<template>
  <div class="account_settings">
    <div class="content">
      <!-- 顶部面包屑 s -->
      <bread-crumb :nav_title="nav_title" path="order" />
      <!-- 顶部面包屑 end-->

      <div class="content_total">
        <!-- 侧边栏 start-->
        <nav-left :xuanzhong="4" />
        <!-- 侧边栏 end-->

        <!-- 右侧展示页 -->
        <div class="nav">
          <div class="evalutions">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <!-- 服务评价--全部评价 start-->
              <el-tab-pane label="全部评价" name="0">
                <div class="none_pj" v-if="show">
                  <img src="../personal/images/none.png" alt="" />
                  <p class="none_text">暂无评价</p>
                </div>
                <div class="evalutions_content" v-else>
                  <!-- 第一个表格 -->
                  <div
                    class="evalutions_table"
                    v-for="(item, index) in orderDataone.data"
                    :key="index"
                  >
                    <table cellspacing="0" cellpadding="0">
                      <tr>
                        <th style="width: 117px">
                          {{ item.type == 1 ? "海师" : "学生" }}
                        </th>
                        <th style="width: 160px">
                          {{ item.type == 1 ? "海师身份" : "意向国家" }}
                        </th>
                        <th style="width: 196px">应用场景</th>
                        <th style="width: 156px">付款状态</th>
                        <th style="width: 136px">评价状态</th>
                        <th style="width: 126px">评价时间</th>
                        <th style="width: 103px">操作</th>
                      </tr>
                      <tr>
                        <td v-if="item.type == 1">{{ item.teacher_name }}</td>
                        <td v-else>{{ item.user_name }}</td>

                        <td class="" v-if="item.type == 1">
                          {{ item.hd_hdsf }}
                        </td>
                        <td v-else-if="item.type == 2">
                          {{ item.countries == null ? "—" : item.countries }}
                        </td>
                        <td>{{ item.service_type }}</td>
                        <td class="evalutions_pay state">
                          {{ item.is_settlement }}
                        </td>
                        <td class="state">{{ item.is_evaluation }}</td>
                        <td class="evalutions_time">
                          <span>{{ item.created_at }}</span>
                        </td>
                        <td class="caozuo">
                          <div v-if="item.type == 1">
                            <el-link
                              type="primary"
                              @click="
                                evalutions_details(item.order_id, item.type)
                              "
                              >详情</el-link
                            >
                          </div>
                          <div v-else-if="item.type == 2">
                            <el-link
                              type="primary"
                              @click="
                                evalutions_details(item.order_id, item.type)
                              "
                              >详情</el-link
                            >
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <!-- 分页器 -->
                <div v-if="showr">
                  <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="orderDataone.count"
                    :current-page="orderDataone.offset"
                    :page-size="2"
                    @current-change="orderclickOne"
                  >
                  </el-pagination>
                </div>
              </el-tab-pane>
              <!-- 服务评价--全部评价 end-->
              <!-- 评价海师 start -->
              <el-tab-pane label="评价海师" name="1">
                <div class="none_pj" v-if="show">
                  <img src="../personal/images/none.png" alt="" />
                  <p class="none_text">暂无评价</p>
                </div>
                <div class="evalutions_content" v-else>
                  <div
                    class="evalutions_table"
                    v-for="(items, index) in orderDatatwo.data"
                    :key="index"
                  >
                    <table cellspacing="0" cellpadding="0">
                      <tr>
                        <th style="width: 117px">海师</th>
                        <th style="width: 160px">海师身份</th>
                        <th style="width: 196px">应用场景</th>
                        <th style="width: 156px">付款状态</th>
                        <th style="width: 136px">评价状态</th>
                        <th style="width: 126px">评价时间</th>
                        <th style="width: 103px">操作</th>
                      </tr>
                      <tr>
                        <td>{{ items.teacher_name }}</td>
                        <td>{{ items.hd_hdsf }}</td>
                        <td>{{ items.service_type }}</td>
                        <td class="evalutions_pay state">
                          {{ items.is_settlement }}
                        </td>
                        <td class="state">{{ items.is_evaluation }}</td>
                        <td class="evalutions_time">
                          <span>{{ items.created_at }}</span>
                        </td>
                        <td class="caozuo">
                          <!-- 详情 start-->
                          <div>
                            <el-link
                              type="primary"
                              @click="
                                evalutions_details(items.order_id, items.type)
                              "
                              >详情</el-link
                            >
                          </div>
                          <!-- 详情 end-->
                        </td>
                      </tr>
                    </table>
                  </div>
                  <!-- 分页器 -->
                  <div v-if="showr">
                    <el-pagination
                      v-if="showr"
                      background
                      layout="prev, pager, next"
                      :total="orderDatatwo.count"
                      :current-page="orderDatatwo.offset"
                      :page-size="2"
                      @current-change="orderclickOne"
                    >
                    </el-pagination>
                  </div>
                </div>
              </el-tab-pane>
              <!-- 评价海师 end -->
              <!-- 评价学生 start -->
              <el-tab-pane label="评价学生" name="2">
                <div class="none_pj" v-if="show">
                  <img src="../personal/images/none.png" alt="" />
                  <p class="none_text">暂无评价</p>
                </div>
                <div class="evalutions_content" v-else>
                  <div
                    class="evalutions_table"
                    v-for="(item, index) in orderDatathree.data"
                    :key="index"
                  >
                    <table cellspacing="0" cellpadding="0">
                      <tr>
                        <th style="width: 117px">学生</th>
                        <th style="width: 160px">意向国家</th>
                        <th style="width: 196px">应用场景</th>
                        <th style="width: 156px">付款状态</th>
                        <th style="width: 136px">评价状态</th>
                        <th style="width: 126px">评价时间</th>
                        <th style="width: 103px">操作</th>
                      </tr>
                      <tr>
                        <td>{{ item.user_name }}</td>
                        <td>
                          {{ item.countries == null ? "—" : item.countries }}
                        </td>
                        <td>{{ item.service_type }}</td>
                        <td class="evalutions_pay state">
                          {{ item.is_settlement }}
                        </td>
                        <td class="state">{{ item.is_evaluation }}</td>
                        <td class="evalutions_time">
                          <span>{{ item.created_at }}</span>
                        </td>
                        <td class="caozuo">
                          <div v-if="item.type == 1">
                            <el-link
                              type="primary"
                              @click="
                                evalutions_details(item.order_id, item.type)
                              "
                              >详情</el-link
                            >
                          </div>
                          <div v-if="item.type == 2">
                            <el-link
                              type="primary"
                              @click="
                                evalutions_details(item.order_id, item.type)
                              "
                              >详情</el-link
                            >
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <!-- 分页器 -->
                  <div v-if="showr">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="orderDatathree.count"
                      :current-page="orderDatathree.offset"
                      :page-size="2"
                      @current-change="orderclickOne"
                    >
                    </el-pagination>
                  </div>
                </div>
              </el-tab-pane>
              <!-- 评价学生 end -->
              <!--服务评价，未评价 start-->
              <el-tab-pane label="未评价" name="3">
                <div class="none_pj" v-if="show">
                  <img src="../personal/images/none.png" alt="" />
                  <p class="none_text">暂无评价</p>
                </div>
                <div class="no_evalution" v-else>
                  <!-- 表格标题 start-->
                  <div class="no_evalution_title">
                    <tr>
                      <th style="width: 293px">订单详情</th>
                      <th style="width: 89px">学生姓名</th>
                      <th style="width: 128px">手机号码</th>
                      <th style="width: 143px">合同编号</th>
                      <th style="width: 88px">付款状态</th>
                      <th style="width: 79px">评价状态</th>
                      <th style="width: 130px">操作</th>
                    </tr>
                  </div>
                  <!-- 表格标题 end-->

                  <!-- 第一个表格 -->
                  <div
                    class="no_evalution_nums"
                    v-for="(item, index) in orderDatafour.data"
                    :key="index"
                  >
                    <table cellspacing="0" cellpadding="0">
                      <tr>
                        <td colspan="7" class="no_number">
                          订单编号：<span>{{ item.order_sn }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 293px">
                          <div class="no_evalution_users">
                            <div class="users_info">
                              <el-avatar
                                src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                              />
                              <div class="users_detail">
                                <span>{{ item.teacher_name }}</span>
                                <p>{{ item.service_name }}</p>
                              </div>
                            </div>
                            <div class="yuyue_times">
                              预约时间：<span>{{ item.start_time }}</span> -
                              <span>{{ item.end_time }}</span>
                            </div>
                          </div>
                        </td>
                        <td style="width: 89px">{{ item.user_name }}</td>
                        <td style="width: 128px">{{ item.mobile }}</td>
                        <td style="width: 143px">{{ item.contract_number }}</td>
                        <td style="width: 88px">全部结算</td>
                        <td style="width: 79px">{{ item.is_evaluation }}</td>
                        <td class="no_evalution_caozuo" style="width: 130px">
                          <div class="no_evalution_operation">
                            <el-link
                              target="_blank"
                              @click="ordersdetail(item.id)"
                              >订单详情</el-link
                            >
                            <el-tag @click="writepj(item.id, item.type)"
                              >评价</el-tag
                            >
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <!-- 分页器 -->
                <div v-if="showr">
                  <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="orderDatafour.count"
                    :current-page="orderDatafour.offset"
                    :page-size="2"
                    @current-change="orderclickOne"
                  >
                  </el-pagination>
                </div>
              </el-tab-pane>
              <!-- 服务评价，未评价 end -->
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import breadCrumb from "./components/breadcrumb";
import NavLeft from "./components/NavLeft";
import { getEvalutionList } from "@/api/order.js";

export default {
  data() {
    return {
      radio: 3,
      activeName: "0",
      confirm: true,
      openOff: true,
      show: false,
      showr: false,
      nav_title: [{ name: "订单中心" }, { name: "服务评价" }],
      orderDataone: {
        //全部评价
        offset: 1,
        data: {},
        count: 1,
      },
      orderDatatwo: {
        //评价海师
        offset: 1,
        data: {},
        count: 1,
      },
      orderDatathree: {
        //评价学生
        offset: 1,
        data: {},
        count: 1,
      },
      orderDatafour: {
        //评价学生
        offset: 1,
        data: {},
        count: 1,
      },
    };
  },
  components: {
    breadCrumb: breadCrumb,
    NavLeft,
  },
  methods: {
    evalutions_details(id, type) {
      this.$router.push({ path: "/evalution", query: { id: id, type: type } });
    },
    writepj(id, type) {
      console.log(222, id);
      this.$router.push({ path: "/writes", query: { id: id, type: type } });
    },
    handleClick(tab, event) {
      console.log(33333, tab, event);
      this.initFun(this.activeName);
    },
    ordersdetail(id) {
      this.$router.push(`/bokkings?orderid=${id}`);
    },
    initFun(type) {
      let pages;
      if (type == 0) {
        pages = this.orderDataone.offset;
      } else if (type == 1) {
        pages = this.orderDatatwo.offset;
      } else if (type == 2) {
        pages = this.orderDatathree.offset;
      } else if (type == 3) {
        pages = this.orderDatafour.offset;
      }
      getEvalutionList({
        type: type,
        offset: pages,
        limit: 2,
      }).then((res) => {
        if (type == 0) {
          //判断在什么状态下显示的数据
          // console.log("initFun", res);
          this.orderDataone.data = res.data.list;
          this.orderDataone.count = res.data.count;
          if (this.orderDataone.data.length == 0) {
            // console.log();
            this.show = true;
            this.showr = false;
          }
        } else if (type == 1) {
          this.orderDatatwo.data = res.data.list;
          this.orderDatatwo.count = res.data.count;
          if (this.orderDataotwo.data.length == 0) {
            // console.log();
            this.show = true;
            this.showr = false;
          }
        } else if (type == 2) {
          this.orderDatathree.data = res.data.list;
          this.orderDatathree.count = res.data.count;
          if (this.orderDatathree.data.length == 0) {
            // console.log();
            this.show = true;
            this.showr = false;
          }
        } else if (type == 3) {
          this.orderDatafour.data = res.data.list;
          // console.log(111111112, this.orderDatafour.data);
          // this.orderDatafour.data = res.data.list;
          this.orderDatafour.count = res.data.count;
          if (this.orderDatafour.data.length == 0) {
            // console.log();
            this.show = true;
            this.showr = false;
          }
        }
      });
      // .catch((err) => console.log(err));
    },

    orderclickOne(val) {
      //点击分页显示下一页数据
      this.orderDataone.offset = val;
      this.initFun(this.activeName);
      // this.initFun(1);
      // this.initFun(2);
      // this.initFun(3);
    },
  },
  mounted() {
    this.initFun(0); //生命周期
  },
};
</script>

<style lang="scss" scoped>
.account_settings {
  background: #f5f5f5;
  padding-bottom: 30px;
  .content {
    width: 1200px;
    margin: 0 auto;
    .content_total {
      display: flex;
      margin-top: 16px;
    }
    .nav {
      width: 990px;
      // height: 1780px;
      background: #ffffff;
      border-radius: 8px;
      margin-left: 10px;
      // 服务评价Start
      /deep/ .el-tabs__header {
        margin: 0;
      }
      .evalutions {
        border-radius: 8px 8px 0px 0px;
        // 表头
        /deep/ .el-tabs__nav-wrap {
          height: 70px;
          line-height: 70px;
          padding-left: 20px;
          .el-tabs__active-bar {
            width: 70px;
            height: 4px;
            border-radius: 2px;
            background-color: #d99d66;
          }
          .el-tabs__item {
            font-size: 18px;
            font-weight: 400;
            color: #666666;
          }
          .el-tabs__item.is-active {
            font-size: 18px;
            font-weight: bold;
            color: #d99d66;
          }
        }
        // 表头下划线隐藏
        /deep/.el-tabs__nav-wrap::after {
          background-color: transparent !important;
        }
        // 表格
        .evalutions_content {
          margin: 20px;
          .evalutions_table {
            width: 950px;
            height: 133px;
            border-radius: 4px;
            border: 1px solid #ededed;
            margin-bottom: 20px;
            th {
              background: #fcfcfc;
              height: 30px;
              font-size: 13px;
              font-weight: 400;
              color: #666666;
              line-height: 30px;
              // border: 1px solid;
            }
            td {
              height: 102px;
              font-size: 13px;
              font-weight: 400;
              color: #333333;
              line-height: 102px;
              border-top: 1px solid #ededed;
              text-align: center;
              border-right: 1px solid #ededed;
            }
            .evalution_state {
              color: #333333;
            }
            .state {
              font-size: 13px;
              font-weight: 400;
              color: #666666;
            }
            .evalutions_time {
              line-height: 16px;
              height: 16px;
            }
            .caozuo {
              border-right: none;
              height: 16px;
              line-height: 16px;
              /deep/.el-link.el-link--info {
                color: #666666;
                margin-top: -40px;
                font-size: 13px;
              }
              .el-button {
                height: 26px;
                line-height: 0px;
                border-radius: 4px;
                width: 68px;
                border: 1px solid rgba(24, 144, 255, 0.5);
                font-size: 13px;
                font-weight: 400;
                color: #1890ff;
                margin-top: -5px;
              }
            }
          }
        }
      }
      // 服务评价End
      .none_pj {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 1130px;
        img {
          width: 210px;
          height: 200px;
        }
        p {
          width: 210px;
          text-align: center;
          height: 24px;
          font-size: 16px;
          font-weight: 400;
          color: #b1c2d9;
          line-height: 24px;
        }
      }
      // 未评价start
      .no_evalution {
        margin: 20px;
        .no_evalution_title {
          width: 950px;
          height: 50px;
          background: #f5f5f5;
          border-radius: 4px;
          font-size: 13px;
          font-weight: 500;
          color: #666666;
          line-height: 50px;
          text-align: center;
        }
        .no_evalution_nums {
          margin-top: 20px;
          width: 950px;
          height: 133px;
          border-radius: 4px;
          border: 1px solid #ededed;
          text-align: center;
          .no_number {
            width: 950px;
            height: 30px;
            background: #fcfcfc;
            border-radius: 4px 4px 0px 0px;
            font-size: 13px;
            font-weight: 400;
            color: #666666;
            padding-left: 20px;
            border: none;
            text-align: left;
          }
          td {
            border-top: 1px solid #ededed;
            font-size: 13px;
            font-weight: 400;
            color: #333333;
            border-right: 1px solid #ededed;
            height: 102px;
            // text-align: center;
          }
          .no_evalution_users {
            text-align: left;

            .users_info {
              display: flex;
              padding-left: 20px;
              flex-wrap: wrap;
              .users_detail {
                margin-left: 5px;
                p {
                  margin-top: 11px;
                }
              }
            }
            .yuyue_times {
              font-size: 12px;
              font-weight: 400;
              color: #999999;
              margin-top: 14px;
              margin-left: 20px;
              span {
                color: #666666;
              }
            }
          }
          .no_evalution_caozuo {
            border-right: none;
          }
          /deep/ .el-tag {
            height: 26px;
            border: 1px solid rgba(24, 144, 255, 0.5);
            // text-align: center;
            display: block;
            margin: auto;
            margin-top: 10px;
          }
        }
      }
      // 未评价end

      // 表头切换 s
      .nav_header {
        border-radius: 8px 8px 0px 0px;
        display: none;
        /deep/ .el-tabs {
          .el-tabs__item {
            line-height: 0px;
            height: 35px;
            font-size: 18px;
            color: #666666;
          }
          .el-tabs__active-bar {
            width: 70px;
            height: 4px;
            background: #d99d66;
            border-radius: 2px;
          }
          .el-tabs__item.is-active {
            color: #d99d66;
            font-weight: bold;
          }
          .el-tabs__item:hover {
            color: #d99d66;
          }
          .el-tabs__nav-wrap::after {
            background-color: #fff !important;
          }
          .el-tabs__nav-scroll {
            background: #fcfcfc;
            padding: 25px 0 0 20px;
          }
        }
        // 表格标题
        /deep/ .el-table thead {
          font-size: 13px;
          font-weight: 500;
          color: #666666;
        }
        /deep/ .el-table--border {
          margin: 20px;
          .el-table td,
          .el-table th.is-leaf {
            font-size: 13px;
            font-weight: 500;
            color: #666666;
            line-height: 20px;
            // text-align: center;
          }
          .el-table__body td {
            text-align: center;
          }
        }
        // 表头边框去除
        /deep/ .el-table th,
        .el-table tr {
          background: #f5f5f5;
          border-radius: 4px 4px 0px 0px;
          text-align: center;
          border: none;
        }
      }
      // 表头切换 end
    }
    // 分页器start
    /deep/ .el-pagination {
      color: #999999;
      text-align: center;
      padding-bottom: 40px;
      .el-pager li:not(.disabled).active {
        width: 44px;
        height: 30px;
        background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
        border-radius: 4px 8px 4px 8px;
        font-size: 16px;
        color: #ffffff;
      }
      .btn-prev,
      .el-pager li {
        width: 44px;
        height: 30px;
        border-radius: 4px 8px 4px 8px;
        border: 1px solid #ededed;
        font-size: 16px;
        font-weight: 400;
        color: #999999;
      }
      .btn-next {
        width: 44px;
        height: 30px;
        border-radius: 4px 8px 4px 8px;
        border: 1px solid #ededed;
        color: #cfcfcf;
      }
    }
    // 分页器end
  }
  // 订单详情
  .details {
    display: flex;
    position: relative;
    .dindan {
      position: absolute;
      top: 1px;
      left: 1px;
    }
    .users {
      margin-left: 5px;
      text-align: left;
      span {
        font-size: 14px;
        color: #666666;
        font-weight: 400;
      }
      p {
        font-size: 13px;
        font-weight: 500;
        color: #333333;
      }
    }
  }
  .appointment {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    margin-top: 10px;
    span {
      color: #666666;
    }
  }
  // 操作start
  .el-tag {
    width: 68px;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    border: 1px solid rgba(24, 144, 255, 0.5);
    text-align: center;
    background: #ffffff;
    cursor: pointer;
  }
  .look_detail {
    font-size: 13px;
    font-weight: 400;
    color: #666666;
    margin-top: 10px;
    cursor: pointer;
  }
  .cancel_order {
    font-size: 13px;
    font-weight: 400;
    color: #666666;
    margin-top: 10px;
    cursor: pointer;
  }
  // 操作end
}
</style>
